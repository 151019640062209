import { ctsApi } from "@/views/channel/api";
import confirm from './../components/confirm'
import appeal from './../components/appeal'
export default {
  name: "",
  components:{
    confirm,
    appeal
  },
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/center/todoList",
          name: "/center/todoList",
          title: "待办"
        },
        {
          path: "",
          name: "",
          title: "政策对账单"
        },
      ],
      nolistImg: require("@/assets/IntentionList/none.png"),
      applearVisible:false,
      visible:false,
      isFixed:false,
      policyWindowList:[],
      monthDate:'',
      pageLoadFlag:false,

    };
  },
  mounted() {

    window.addEventListener(
      "scroll",
      this.throttle(this.handleScroll, 10),
      true
    );
    this.getList()
  },


  methods: {
    // 底部固定部分代码
    //throttle：
    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    },

    gotoDetail(item){
      this.$router.push({
        path:'/policyRebate/rebateDetail',
        query:{
          type: this.$route.query.isRead == "true" ? 2 : 1,
          monthDate:this.monthDate,
          item:JSON.stringify(item)
        }
      })
    },
    // 获取cts接口
    ctsApiData(data){
      return ctsApi(data)
    },
    async getList(){
      let data = {
        "companyCode": "",
        "merchantMdmCode": this.$store.state.user.userInfo.customer.customerCode,
        policyType:'',
        productCode:'',
        settlementStatus:4,
        monthDate:'',
        clearingSheetNoList:this.$route.query.id?this.$route.query.id.split(','):[],
        settlementType: this.$route.query.isRead == "true" ? "0" : "",
        "serviceCode":this.$route.query.isRead == "true" ? 'fetchDetailsForCompletedConfirmation' : 'retrievePendingConfirmationSubmissions',
      }
      this.pageLoadFlag = true
      const res = await this.ctsApiData(data)
      if(this.$route.query.isRead == "true"){
        if(res.data.data){
          res.data.data.forEach(item => {
            item.activityName = item.promotionPlan;
          })
          this.policyWindowList = res.data.data;
        }
      }else{
        this.policyWindowList = res.data.code == 200&&res.data.data?res.data.data.pendingOrAppealDataVOList:[]
      }
      this.pageLoadFlag = false
    },
    batchOk(type) {
      if (type == 'confirm'){
        this.visible = true
      } else {
        this.applearVisible = true
      }
    },
    conformOk(e,type){
      let idsList = []
      if (this.policyWindowList.length>0){
        idsList = this.policyWindowList.map(el => {
          return {
            clearingsheetNo:el.settlementNumber,
            confirmPeriod: this.$util.formatDate(new Date().getTime() - 86400000, 'YM'),
            feedbackStatus:type,
            appealReason:type==1?'':e,
          }
        })
      }
      let data = {
        // monthDate: this.$util.formatDate(new Date().getTime() - 86400000, 'Y-M'),
        albatrossStatusConfirmationResponseDto:idsList,
        'serviceCode':'confirmSettlementFeedbackAlbatrossInterface',
      }
      ctsApi(data).then(res=>{
        console.log('res',res)
        if(res.data.message == '确认成功' || res.data.message == '申诉成功'){
          this.$router.push({path:'/center/todoList'})
        }
      })
    },

  }
};
